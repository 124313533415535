<template>
  <div>
    <div class="ft20 cl-black cl-main ftw600">服务人员统计</div>

    <div class="mt20">
      <div class="form-search-box ">
        <a-form layout="inline">
          <a-form-item label="服务人员">
            <a-input v-model="search.name" placeholder="请输入服务人员名称"></a-input>
          </a-form-item>

          <a-form-model-item label="服务时间" >
            <a-range-picker
                :show-time="{ format: 'HH:mm' }"
                format="YYYY-MM-DD"
                :placeholder="['服务开始时间', '服务结束时间']"
                @ok="onOkAct"
                @change="changeAct"
                v-model="search.times"
            />
          </a-form-model-item>

          <a-form-item>
            <a-button @click="searchAct" type="primary">查询</a-button>
            <a-button @click="cancelAct" class="ml10">取消</a-button>
            <a-button type="primary" class="ml10" icon="export" @click="exportAct">导出数据</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>

    <div class="mt20">
      <div class="bg-w">
        <div class="pd20">
          <div class="wxb-table-gray">
            <a-table rowKey="serviceman_id" :columns="columns" :pagination="pagination" :data-source="datas" @change="handleTableChange" :loading="loading">
              <div class="flex alcenter" slot="vip" slot-scope="record,index">
                <img v-if="record.face!=null" :src="record.face" class="index-face" style="width: 40px;height: 40px;" />
                <img v-else src="../../assets/image/woyaoyou_head_defult@2x.png" class="index-face" style="width: 40px;height: 40px;" />
                <div class="ml5" style="text-align: left;">
                  <div>{{record.name}}</div>
                  <div>{{record.mobile}}</div>
                </div>
              </div>

              <div class="flex alcenter" slot="contacts" slot-scope="record,index">
                <div class="ml5" style="text-align: left;">
                  <div>{{record.contacts}}</div>
                  <div>{{record.mobile}}</div>
                </div>
              </div>
              <div slot="status" slot-scope="status,record">
                <a-switch :loading="switchloading"  v-model="record.statusswitch" checked-children="显示"  un-checked-children="隐藏" @change="onClickswitch(record)"  />
              </div>
              <div class="flex alcenter" slot="activetime" slot-scope="record,index">
                <div class="ml5" style="text-align: left;">
                  <div>开始：{{record.start_time}}</div>
                  <div>结束：{{record.end_time}}</div>
                </div>
              </div>

              <div class="flex alcenter left" slot="peoples" slot-scope="record,index">
                <div class="ml5">
                  <div>线上报名：{{record.addcount}}人</div>
                  <div>线上签到：{{record.signcount}}人</div>
                  <div>线下签到：{{record.offline_line}}人</div>
                </div>
              </div>

              <div class="flex alcenter left"  slot="type" slot-scope="type">
                {{ type != null ? type.name : '暂无分类' }}
              </div>

            </a-table>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {listMixin} from '../../common/mixin/list.js';
export default {
  mixins: [listMixin],
  data() {
    return {
      loading: false,
      editloading:false,
      miniqrcode:'',
      confirmLoading:false,
      pagination: {
        current: 1,
        pageSize: 10, //每页中显示10条数据
        total: 0,
      },
      search: {
        name: '',
        times: [],
      },
      level:[],
      columns: [
        {title: '编号',dataIndex: 'serviceman_id',align: 'center',width:100},
        {title: '服务人员',key: 'name',align: 'left',width:300,scopedSlots: {customRender: 'vip'}},
        {title: '工单数',dataIndex: 'appointments',align: 'right',ellipsis: true},
        {title: '累计服务时长',dataIndex: 'service_times',width:180,align: 'right',ellipsis: true},
        {title: '建档数',dataIndex: 'elders',align: 'right',ellipsis: true},
        {title: '随访数',dataIndex: 'access',align: 'right',ellipsis: true},
        {title: '上门随访数',dataIndex: 'access_house',align: 'right',ellipsis: true},
        {title: '电话随访数',dataIndex: 'access_tel',align: 'right',ellipsis: true},
      ],
      datas: [],
      member:{},
    }
  },
  methods: {
    cancelAct(){
      this.search.name='';
      this.search.times=[];
      this.getLists();
    },
    exportAct() {
      let href = process.env.VUE_APP_BASE_API + "housekeeping/admin/expServicemans?client_env="+localStorage.getItem('client-env-platform')+"&auth_token=" + encodeURIComponent(localStorage.getItem('housekeeping-token'));
      if (this.search.name != '') {
        href = href + '&name=' + encodeURIComponent(this.search.name);
      }
      if (this.search.times.length>0) {
        href = href + '&times=' + encodeURIComponent(this.search.times);
      }
      window.location.href = href;
    },
    // exportAct222(){
    //   if(this.loading==true) return;
    //   this.loading=true;
    //   this.$http.api('admin/expServicemans',{
    //     name:this.search.name,
    //     times:this.search.times,
    //   }).then(res=>{
    //     this.pagination.total=res.total;
    //     this.pagination.showTotal=(total)=>"共"+res.total+"条";
    //     this.datas=res.list;
    //     this.level=res.level;
    //     this.loading=false;
    //   }).catch(res=>{
    //     this.loading=false;
    //   })
    // },
    getLists(){
      if(this.loading==true) return;
      this.loading=true;
      this.$http.api('admin/getServicemans',{
        name:this.search.name,
        times:this.search.times,
        limit:this.pagination.pageSize,
        page:this.pagination.current,
      }).then(res=>{
        this.pagination.total=res.total;
        this.pagination.showTotal=(total)=>"共"+res.total+"条";
        this.datas=res.list;
        this.level=res.level;
        this.loading=false;
      }).catch(res=>{
        this.loading=false;
      })
    },
    onOkAct(value) {
      console.log('onOkAct: ', value);
    },
    changeAct(date,dateString){
      this.search.times = dateString;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      this.getLists();
    }
  }
}
</script>

<style>
.index-face {
  width: 44px;
  height: 44px;
  border-radius: 44px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
  border: 2px solid #FFFFFF;
}

.member-index-action {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: #FFFFFF;
  border: 1px solid #EBEDF5;
  text-align: center;
  line-height: 32px;
}
</style>
